(function () {'use strict';}());
angular.module('app').service('AppService', function($http)
{
	var trace = angular.trace;
	var lookup = {};
	var listing = [];

	var self = {};
	self.postMessage = function(message,apps){
		return $http.post('php/service/PushNotificationService.php',{request:"post",message:message,apps:apps}).success(onSuccess).error(onError);
	};


	function onSuccess(data)
	{
		trace("success",data);
	}
	function onError(data)
	{
		trace("error",data);
	}
	return self;
});