(function () {'use strict';}());

angular.module('app')
  .controller('AppController', function ($scope,AppService) {
  	var trace = angular.trace;

  	$scope.authenticated = false;
  	$scope.data = {code:null,apps:{},message:null};
  	$scope.saving = false;
  	$scope.message = null;

  	$scope.onSubmit = function()
  	{
  		if(!$scope.authenticated) return authenticate();
  		else return post();
  	};

  	$scope.clearMessage = function(){
  		$scope.message = null;
  	};

    $scope.selectAll = function(value){
      $scope.data.apps.free = value;
      $scope.data.apps.basic = value;
      $scope.data.apps.pro = value;
    };


  	function authenticate()
  	{
  		if($scope.data.code === "11Cookies") $scope.authenticated = true;
  	}

  	function post()
  	{
  		$scope.saving =  true;
  		AppService.postMessage($scope.data.message, $scope.data.apps).then(onMessageSend);
  	}

  	function onMessageSend()
  	{
  		$scope.saving = false;
  		$scope.data.message = null;
      $scope.data.apps = {};
  		$scope.message = "Message posted successfully.";
  	}


  });
